import {
  apiServiceState,
  loadingState,
  referBusinessTokenState,
  referFriendTokenState,
  shareAdMessageTokenState,
  sharePlaceTokenState,
} from "../atoms";
import { ConsumerApi } from "../ConsumerApi.dto";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useRecoilValue } from "recoil";
import { DateTime } from "luxon";
import * as i18n from "../i18n";
import { InvitationProps, ShareAndReferTeaser } from "./ShareAndReferTeaser";
import { Place } from "../place";
import { convertSharedPlaceToPlaceDto } from "helpers/PlaceHelper";
import { SignIn } from "./SignIn";
import { convertSharedAdMessageToAdMessageDto } from "helpers/AdHelper";
import { AdMessage } from "ad-message";

export const Welcome: React.FC = () => {
  const { t } = useTranslation();
  const setLoading = useSetRecoilState(loadingState);
  const [ready, setReady] = useState(false);

  const apiService = useRecoilValue(apiServiceState);

  const [invitationDaysLeft, setInvitationDaysLeft] = useState<number>();

  const [signInContent, setSignInContent] = useState<React.ReactNode>(
    <DefaultSignInContent />,
  );

  const [referFriendToken, setReferFriendToken] = useRecoilState(
    referFriendTokenState,
  );
  const [referFriendLink, setReferFriendLink] =
    useState<ConsumerApi.ReferFriendLinkUsedResponse>();

  const [referBusinessToken, setReferBusinessToken] = useRecoilState(
    referBusinessTokenState,
  );
  const [referBusinessLink, setReferBusinessLink] =
    useState<ConsumerApi.ReferBusinessLinkUsedResponse>();

  const [sharePlaceToken, setSharePlaceToken] =
    useRecoilState(sharePlaceTokenState);
  const [sharePlaceLink, setSharePlaceLink] =
    useState<ConsumerApi.SharePlaceLinkUsedResponse>();

  const [shareAdMessageToken, setShareAdMessageToken] = useRecoilState(
    shareAdMessageTokenState,
  );
  const [shareAdMessageLink, setShareAdMessageLink] =
    useState<ConsumerApi.ShareAdMessageLinkUsedResponse>();

  const fetch = useCallback(async () => {
    try {
      if (
        referFriendToken ||
        referBusinessToken ||
        sharePlaceToken ||
        shareAdMessageToken
      ) {
        setLoading(true);

        if (referFriendToken) {
          const res = await apiService.post(
            new ConsumerApi.ReferFriendLinkUsedRequest({
              referFriendToken: referFriendToken,
            }),
          );

          setReferFriendLink(res);
          switch (res.state) {
            case ConsumerApi.ReferFriendState.Expired:
              setReferFriendToken(undefined);
              break;
            case ConsumerApi.ReferFriendState.Available:
              setInvitationDaysLeft(
                Math.ceil(
                  DateTime.fromISO(res.expiresAt as string).diffNow(["days"])
                    .days,
                ),
              );
              setSignInContent(
                <ReferFriendSignInContent
                  firstName={res.referringUser?.firstName}
                  lastName={res.referringUser?.lastName}
                />,
              );
              break;
            case ConsumerApi.ReferFriendState.Awarded:
            case ConsumerApi.ReferFriendState.CanNotReferYourself:
            case ConsumerApi.ReferFriendState.YouWereAlreadySignedUp:
            default:
            // Ignore. Logically impossible.
          }
        } else if (referBusinessToken) {
          const res = await apiService.post(
            new ConsumerApi.ReferBusinessLinkUsedRequest({
              referBusinessToken: referBusinessToken,
            }),
          );
          setReferBusinessLink(res);
          switch (res.state) {
            case ConsumerApi.ReferBusinessState.Expired:
              setReferBusinessToken(undefined);
              break;
            case ConsumerApi.ReferBusinessState.SignInToSee:
              setInvitationDaysLeft(
                Math.ceil(
                  DateTime.fromISO(res.expiresAt as string).diffNow(["days"])
                    .days,
                ),
              );
              break;
            case ConsumerApi.ReferBusinessState.Available:
            case ConsumerApi.ReferBusinessState.CanNotReferYourself:
            case ConsumerApi.ReferBusinessState.AlreadyClaimedByYou:
            case ConsumerApi.ReferBusinessState.AlreadyClaimedBySomeoneElse:
            default:
            // Ignore. Logically impossible.
          }
        } else if (sharePlaceToken) {
          const res = await apiService.post(
            new ConsumerApi.SharePlaceLinkUsedRequest({
              sharePlaceToken: sharePlaceToken,
              locale: i18n.getDefault(),
            }),
          );
          setSharePlaceLink(res);
          switch (res.state) {
            case ConsumerApi.SharingState.Expired:
              setSharePlaceToken(undefined);
              break;
            case ConsumerApi.SharingState.Teaser:
              setInvitationDaysLeft(
                Math.ceil(
                  DateTime.fromISO(res.expiresAt as string).diffNow(["days"])
                    .days,
                ),
              );
              break;
            case ConsumerApi.SharingState.Awarded:
            case ConsumerApi.SharingState.SelfShare:
            default:
            // Ignore. Logically impossible.
          }
        } else if (shareAdMessageToken) {
          const res = await apiService.post(
            new ConsumerApi.ShareAdMessageLinkUsedRequest({
              shareAdMessageToken: shareAdMessageToken,
              locale: i18n.getDefault(),
            }),
          );
          setShareAdMessageLink(res);
          switch (res.state) {
            case ConsumerApi.SharingState.Expired:
              setShareAdMessageToken(undefined);
              break;
            case ConsumerApi.SharingState.Teaser:
              setInvitationDaysLeft(
                Math.ceil(
                  DateTime.fromISO(res.expiresAt as string).diffNow(["days"])
                    .days,
                ),
              );
              break;
            case ConsumerApi.SharingState.Awarded:
            case ConsumerApi.SharingState.SelfShare:
            default:
            // Ignore. Logically impossible.
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  }, [
    apiService,
    referBusinessToken,
    referFriendToken,
    setLoading,
    setReferBusinessToken,
    setReferFriendToken,
    setShareAdMessageToken,
    setSharePlaceToken,
    shareAdMessageToken,
    sharePlaceToken,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const place = useMemo(() => {
    if (sharePlaceLink && sharePlaceLink.place) {
      return convertSharedPlaceToPlaceDto(sharePlaceLink.place);
    } else if (referBusinessLink && referBusinessLink.referredPlace) {
      return convertSharedPlaceToPlaceDto(referBusinessLink.referredPlace);
    }
    return undefined;
  }, [sharePlaceLink, referBusinessLink]);

  const adMessage = useMemo(
    () =>
      shareAdMessageLink &&
      shareAdMessageLink.adMessage &&
      shareAdMessageLink.place
        ? convertSharedAdMessageToAdMessageDto(
            shareAdMessageLink.adMessage,
            shareAdMessageLink.place,
          )
        : undefined,
    [shareAdMessageLink],
  );

  const shareAndReferProps = useMemo<InvitationProps | undefined>(() => {
    if (
      referBusinessLink &&
      referBusinessLink.state === ConsumerApi.ReferBusinessState.SignInToSee &&
      place
    ) {
      return {
        firstName: referBusinessLink.referringUser?.firstName,
        lastName: referBusinessLink.referringUser?.lastName,
        preview: <Place place={place} teaser={true} />,
        invitationMessage: (
          <span>
            {t("invites you to manage {{businessName}}.", {
              businessName: referBusinessLink.referredPlace?.name,
            })}
          </span>
        ),
        bodyMessage: (
          <Trans>
            Brovs is a website where you can browse Ads, Products and Services
            at Local Businesses.
            <br />
            <br />
            As Owner or Employee you get access to the Brovs Business Platform
            so you can Manage the Business Profile for FREE.
          </Trans>
        ),
      };
    } else if (
      sharePlaceLink &&
      sharePlaceLink.state === ConsumerApi.SharingState.Teaser &&
      place
    ) {
      return {
        firstName: sharePlaceLink.sharingUser?.firstName,
        lastName: sharePlaceLink.sharingUser?.lastName,
        preview: <Place place={place} teaser={true} />,
        invitationMessage: (
          <Trans>
            thinks you have an interest in
            <br />
            {place.name}.
          </Trans>
        ),
        bodyMessage: (
          <Trans>
            Brovs is a website where you can browse Ads, Products and Services
            at Local Businesses.
            <br />
            <br />
            You earn Reward Points by Signing Up or Viewing the Business
            Profile.
          </Trans>
        ),
      };
    } else if (
      shareAdMessageLink &&
      shareAdMessageLink.state === ConsumerApi.SharingState.Teaser &&
      adMessage
    ) {
      return {
        firstName: shareAdMessageLink.user?.firstName,
        lastName: shareAdMessageLink.user?.lastName,
        preview: <AdMessage adMessage={adMessage} type="teaser" />,
        invitationMessage: (
          <span>{t("thinks you have an interest in this Ad")}</span>
        ),
        bodyMessage: (
          <Trans>
            Brovs is a website where you can browse Ads, Products and Services
            at Local Businesses.
            <br />
            <br />
            You earn Reward Points by Signing Up or Viewing the Business
            Profile.
          </Trans>
        ),
      };
    } else {
      return undefined;
    }
  }, [
    referFriendLink,
    referBusinessLink,
    sharePlaceLink,
    place,
    shareAdMessageLink,
    adMessage,
  ]);

  return ready ? (
    shareAndReferProps ? (
      <ShareAndReferTeaser {...shareAndReferProps} />
    ) : (
      <SignIn content={signInContent} />
    )
  ) : null;
};

const DefaultSignInContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="font-bold text-center text-white w-full px-[10px] mt-[32px] text-h6">
        <Trans>
          Browse ads, products and
          <br />
          services at local businesses
        </Trans>
      </div>
      <div className="font-bold text-center text-white w-full mt-[72px] text-h6">
        {t("Fashion")}
      </div>
      <div className="font-bold text-center text-white w-full mt-[16px] text-h6">
        {t("Food & Drink")}
      </div>
      <div className="font-bold text-center text-white w-full mt-[16px] text-h6">
        {t("Shopping Malls")}
      </div>
    </>
  );
};

interface ReferFriendSignInContentProps {
  firstName?: string;
  lastName?: string;
}

const ReferFriendSignInContent: React.FC<ReferFriendSignInContentProps> = (
  props,
) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center text-white w-full px-[10px] mt-[60px] mb-[22px] text-h7">
        <div className="text-center uppercase text-h6">
          {props.firstName}
          {props.lastName ? " " + props.lastName.charAt(0) : ""}.
        </div>
        <div className="text-center text-h7">
          {t("thinks you have an interest in using Brovs")}
        </div>
      </div>
      <div className="text-center text-white w-full px-[10px] mt-[60px] mb-[22px] text-h7">
        {t(
          "Sign In now to receive Reward Points and browse Ads, Products and Services at Local Businesses.",
        )}
      </div>
    </>
  );
};
