import { PlaceOpeningHoursDescription } from "../place-opening-hours-description";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { lastLocationState, localeState, userProfileState } from "../atoms";
import { industryFoodAndDrink } from "../Constants";
import { ConsumerApi } from "../ConsumerApi.dto"
import { formatPriceMainDish, calculateDistance, distanceDescription } from "../helpers/PlaceHelper";
import { ArrowRightSmallIcon, ClaimedPlaceIcon, MenuInlineIcon, PlaceDistanceIcon } from "../icons";
import { PlaceOpenHourInfo } from "../model/PlaceOpenHourInfo";
import { PlaceMap } from "../place-map";
import { PlaceLogo } from "../place-logo";

type PlaceProps = {
  place: ConsumerApi.PlaceBasicDto
  onClickOpeningHours?: () => void
  onClickPlaceName?: () => void
  onClickOptionsMenu?: () => void
  teaser?: boolean
}

export const PlaceHeader: React.FC<PlaceProps> = (props) => {

  const locale = useRecoilValue(localeState);

  const place = props.place;

  const userProfile = useRecoilValue(userProfileState);
  const lastLocation = useRecoilValue(lastLocationState);
  const [showMap, setShowMap] = useState(false);
  const [distance, setDistance] = useState<string>();

  const onClickOpeningHours = !props.teaser ? props.onClickOpeningHours : undefined;
  const onClickPlaceName = !props.teaser ? props.onClickPlaceName : undefined;
  const onClickOptionsMenu = !props.teaser ? props.onClickOptionsMenu : undefined;

  const description = useMemo(() => {
    const category = place.categories?.find(c => c.absoluteSlug.indexOf(".") === -1) as ConsumerApi.CategoryTreeDto;
    if (category) { // TODO: Temporary condition added to support Shopping Malls result, since Shopping Malls places are not returning categories
      let description: string[] = [];
      if (category.absoluteSlug === industryFoodAndDrink && category.children) {
        description = description.concat(category.children
          .filter(c => c.absoluteSlug.split('.').length === 2)
          .map(c => c.name));

        if (place.priceMainDish) {
          description.push(formatPriceMainDish(place.priceMainDish));
        }
      } else {
        description.push(category.name);
        description = description.concat(place.wearPriceProfiles
          .map(wpp => wpp.label.replace(/[^\$]+/, ""))
          .filter(wpp => wpp.length > 0));
      }
      return description;
    } else {
      return [];
    }
  }, [place]);
  const openHourInfo = useMemo(() => new PlaceOpenHourInfo(place, locale), [place, locale]);

  useEffect(() => {
    if (lastLocation && userProfile && place.longitude && place.latitude && userProfile.showDistancesIn) {
      const distance = calculateDistance(
        lastLocation.location.lng,
        lastLocation.location.lat,
        place.longitude, place.latitude,
        userProfile.showDistancesIn);
      setDistance(distanceDescription(distance, userProfile.showDistancesIn));
    }
  }, [lastLocation, place.latitude, place.longitude, userProfile])

  return (
    <>
      <div
        className="px-[12px] pt-[20px] pb-[7px] flex min-w-0 max-w-full">
        <PlaceLogo place={place} onClick={onClickPlaceName} />
        <div className="pl-[10px] min-w-0 flex-auto">
          <div className="flex items-center">
            <div onClick={onClickPlaceName} className={`flex flex-auto items-center ${onClickPlaceName ? "cursor-pointer" : ""}`}>
              <div className="style-subtitle truncate">
                {place.name}
              </div>
              {place.claimed ?
                <div className="pl-[5px]">
                  <ClaimedPlaceIcon />
                </div>
              : null}
            </div>
            {!props.teaser ?
              <div onClick={onClickOptionsMenu} className={`pl-[20px] flex flex-col flex-none ${onClickOptionsMenu ? 'cursor-pointer' : ""}`}>
                <MenuInlineIcon />
              </div>
            : null}
          </div>
          <div className="text-body truncate pr-[10px] text-gray-dark">
            {description.map((d, i) =>
              <React.Fragment key={i}>
                {i > 0 ? <span className="px-[8px]">|</span> : null}
                <span>{d}</span>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="px-[12px] pb-[7px] flex items-center">
        <PlaceOpeningHoursDescription
          openHourInfo={openHourInfo}
          onClickOpeningHours={onClickOpeningHours} />
        {distance && !props.teaser ?
          <div onClick={() => setShowMap(true)} className="flex-none flex items-center cursor-pointer">
            <div>
              <PlaceDistanceIcon />
            </div>
            <div className="px-[5px] text-body">
              {distance}
            </div>
            <div>
              <ArrowRightSmallIcon />
            </div>
          </div>
        : null}
      </div>

      {!props.teaser && showMap ?
        <PlaceMap place={props.place} onBack={() => setShowMap(false)} />
      : null}
    </>
  )
}