import { useTranslation } from "react-i18next";
import logo from "../images/logo-black.svg";
import { CommonButtons } from "./CommonButtons";

export interface InvitationProps {
  firstName?: string
  lastName?: string
  profilePhotoUrl?: string
  invitationMessage: React.ReactNode
  previewImageUrl?: string
  preview?: React.ReactNode
  bodyMessage: React.ReactNode
}

export const ShareAndReferTeaser: React.FC<InvitationProps> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="flex flex-col overflow-y-scroll h-full items-center py-[40px]">
      <div className="flex justify-center">
        <img src={logo} alt="logo"/>
      </div>

      <div className="w-full max-w-[330px] flex flex-col items-center mt-[35px]">
        <div className="text-center uppercase text-h7">
          {props.firstName}{props.lastName ? " "+ props.lastName.charAt(0) : ""}.
        </div>
        <div className="text-center text-h7">
          {props.invitationMessage}
        </div>
      </div>

      {props.preview ?
        <div className="w-full mt-30px">
          <div className="h-[1px] border-gray-light border-t mx-[12px]"></div>
          {props.preview}
          <div className="h-[1px] border-gray-light border-t mx-[12px]"></div>
        </div>
      : null}

      <div className="max-w-[330px] text-center mt-[30px]">
        {props.bodyMessage}
      </div>
      <div className="max-w-[330px] text-h7 text-center mt-[20px] mb-[30px]">
        {t("Easy and FREE Sign In")}
      </div>

      <CommonButtons
        forImageBackground={false} />

    </div>
  )
}