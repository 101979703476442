import {
  apiServiceState,
  loadingState,
  referBusinessLinkUsedState,
  referBusinessTokenState,
  referFriendLinkUsedState,
  referFriendTokenState,
  shareAdMessageLinkUsedState,
  shareAdMessageTokenState,
  sharePlaceLinkUsedState,
  sharePlaceTokenState,
} from "../atoms";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { RewardsIcon } from "../icons";
import { ConsumerApi } from "../ConsumerApi.dto";
import { Trans, useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { earnedPointsBySignupState } from "atoms/earnedPointsBySignup";
import { useNavigate } from "react-router-dom";

type HeaderProps = {
  title?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClickLeftIcon?: () => void;
  onClickRightIcon?: () => void;
  onClickTitle?: () => void;
  hideBottomBorder?: boolean;
};

type RewardMessage = {
  title: React.ReactNode;
  body: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const rewardMessageRef = useRef<HTMLDivElement>(null);

  const apiService = useRecoilValue(apiServiceState);
  const setLoading = useSetRecoilState(loadingState);
  const navigate = useNavigate();

  const setReferFriendToken = useSetRecoilState(referFriendTokenState);
  const referFriendUsedLink = useRecoilValue(referFriendLinkUsedState);

  const [earnedPointsBySignup, setEarnedPointsBySignup] = useRecoilState(
    earnedPointsBySignupState,
  );

  const [referBusinessToken, setReferBusinessToken] = useRecoilState(
    referBusinessTokenState,
  );
  const referBusinessUsedLink = useRecoilValue(referBusinessLinkUsedState);

  const setSharePlaceToken = useSetRecoilState(sharePlaceTokenState);
  const sharePlaceUsedLink = useRecoilValue(sharePlaceLinkUsedState);

  const setShareAdMessageToken = useSetRecoilState(shareAdMessageTokenState);
  const shareAdMessageUsedLink = useRecoilValue(shareAdMessageLinkUsedState);

  const [rewardMessages, setRewardMessages] = useState<RewardMessage[]>([]);

  const closeRewardMessage = useCallback(() => {
    setEarnedPointsBySignup(false);
    setReferFriendToken(undefined);
    setReferBusinessToken(undefined);
    setShareAdMessageToken(undefined);
    setSharePlaceToken(undefined);
  }, [
    setReferFriendToken,
    setReferBusinessToken,
    setShareAdMessageToken,
    setSharePlaceToken,
    setEarnedPointsBySignup,
  ]);

  const clickOutsideOfRewardMessage = useCallback(
    (event) => {
      if (
        rewardMessageRef.current &&
        !rewardMessageRef.current.contains(event.target)
      ) {
        closeRewardMessage();
      }
    },
    [closeRewardMessage, rewardMessageRef],
  );

  useEffect(() => {
    const _rewardMessages = [];

    // The user only gets points by Sign-Up, so ReferFriendState.Awarded is skipped and
    // earnedPointsBySignup is used to show the message.
    if (
      referFriendUsedLink &&
      referFriendUsedLink.state != ConsumerApi.ReferFriendState.Awarded
    ) {
      switch (referFriendUsedLink.state) {
        case ConsumerApi.ReferFriendState.CanNotReferYourself:
          _rewardMessages.push({
            title: <Trans>Invite Others</Trans>,
            body: (
              <Trans>
                You can't use the Invitation Link you sent to a Friend yourself.
                <br />
                <br />
                Invite Friends from the menu to earn more Reward Points.
              </Trans>
            ),
          });
          break;
        case ConsumerApi.ReferFriendState.YouWereAlreadySignedUp:
          _rewardMessages.push({
            title: <Trans>Already Signed Up</Trans>,
            body: (
              <Trans>
                You have Signed Up earlier.
                <br />
                <br />
                Sign Up Reward Points are only for new users.
                <br />
                <br />
                Invite Friends from the menu to earn more Points.
                <br />
                <br />
                Thank you for using Brovs.
              </Trans>
            ),
          });
          break;
        // TODO: Should be removed
        case ConsumerApi.ReferFriendState.Expired:
          break;
        // Logically impossible. Ignore.
        case ConsumerApi.ReferFriendState.Available:
        default:
      }
      // The user gets points by Sign-Up, so ReferFriendState.Available is use to skip ReferFriend messages
      // and earnedPointsBySignup is used to show the message.
    } else if (
      referBusinessUsedLink &&
      referBusinessUsedLink.referredPlace &&
      referBusinessUsedLink.state != ConsumerApi.ReferBusinessState.Available
    ) {
      const placeName = referBusinessUsedLink.referredPlace.name;

      switch (referBusinessUsedLink.state) {
        case ConsumerApi.ReferBusinessState.AlreadyClaimedByYou:
          _rewardMessages.push({
            title: <Trans>You already Manage this Business Profile</Trans>,
            body: <Trans>No further action is needed.</Trans>,
          });
          break;
        case ConsumerApi.ReferBusinessState.CanNotReferYourself:
          _rewardMessages.push({
            title: <Trans>Invite Others</Trans>,
            body: (
              <Trans>
                You can't use the Business Invitation Link yourself.
                <br />
                <br />
                If there has occurred an error, contact Customer Service. You
                can find the link in the menu.
              </Trans>
            ),
          });
          break;
        case ConsumerApi.ReferBusinessState.AlreadyClaimedBySomeoneElse:
          _rewardMessages.push({
            title: <Trans>Business is already Managed</Trans>,
            body: (
              <Trans>
                <span className="text-red">
                  <span className="font-bold">{placeName}</span> has already
                  been Accessed and the Business Profile is Manged by someone
                  else.
                </span>
                <br />
                <br />
                Notify the <span className="text-blue">
                  Account Owner
                </span> to request Access to this Business Profile.
                <br />
                <br />
                If you think there has been a mistake or violation of use,
                please contact{" "}
                <span className="text-blue">
                  Customer Service
                </span>.
              </Trans>
            ),
          });
          break;
        // TODO: Should be removed
        case ConsumerApi.ReferBusinessState.Expired:
          break;
        // Logically impossible. Ignore.
        case ConsumerApi.ReferBusinessState.SignInToSee:
        default:
      }
    } else if (sharePlaceUsedLink) {
      const referringUser = sharePlaceUsedLink.sharingUser
        ? `${sharePlaceUsedLink.sharingUser.firstName} ${sharePlaceUsedLink.sharingUser.lastName}`
        : undefined; // Undefined only happens when SharingState is expired
      switch (sharePlaceUsedLink.state) {
        case ConsumerApi.SharingState.Awarded:
          if (earnedPointsBySignup) {
            _rewardMessages.push({
              title: <Trans>Welcome to Brovs</Trans>,
              body: (
                <Trans>
                  You earned Reward Points just by Signing Up and Viewing the
                  Business Profile.
                  <br />
                  <br />
                  Invite Friends from the menu to earn more Points or Share
                  Business Profiles and Ads.
                  <br />
                  <br />
                  Check out Lottery and how to use the Points.
                </Trans>
              ),
            });
          } else {
            _rewardMessages.push({
              title: <Trans>You earned Reward Points</Trans>,
              body: (
                <Trans>
                  You earned Reward Points just by Viewing the Shared Business
                  Profile.
                  <br />
                  <br />
                  Invite Friends from the menu to earn more Reward Points or
                  Share Business Profiles and Ads.
                  <br />
                  <br />
                  Check out Lottery and how to use the Points.
                </Trans>
              ),
            });
          }
          break;
        case ConsumerApi.SharingState.SelfShare:
          _rewardMessages.push({
            title: <Trans>Share with Others</Trans>,
            body: (
              <Trans>
                You can’t use the Share Business Profile Link yourself to earn
                Reward Points.
                <br />
                <br />
                If there has occurred an error, contact Customer Support. You
                can find the link in the menu.
              </Trans>
            ),
          });
          break;
        // TODO: In the server this should be AlreadyUsedBySomeoneElse
        case ConsumerApi.SharingState.Expired:
          _rewardMessages.push({
            title: <Trans>Invitation has already been used</Trans>,
            body: (
              <Trans>
                This Invitation Link has already been used.
                <br />
                <br />
                Invite Friends from the menu to earn more Reward Points or Share
                Business Profiles and Ads.
              </Trans>
            ),
          });
          break;
        // Logically impossible. Ignore.
        case ConsumerApi.SharingState.Teaser:
        default:
      }
    } else if (shareAdMessageUsedLink) {
      const referringUser = shareAdMessageUsedLink.user
        ? `${shareAdMessageUsedLink.user.firstName} ${shareAdMessageUsedLink.user.lastName}`
        : undefined; // Undefined only happens when SharingState is expired

      switch (shareAdMessageUsedLink.state) {
        case ConsumerApi.SharingState.Awarded:
          if (earnedPointsBySignup) {
            _rewardMessages.push({
              title: <Trans>Welcome to Brovs</Trans>,
              body: (
                <Trans>
                  You earned Reward Points just by Signing Up and Viewing the
                  Ad.
                  <br />
                  <br />
                  Invite Friends from the menu to earn more Points or Share
                  Business Profiles and Ads.
                  <br />
                  <br />
                  Check out Lottery and how to use the Points.
                </Trans>
              ),
            });
          } else {
            _rewardMessages.push({
              title: <Trans>You earned Reward Points</Trans>,
              body: (
                <Trans>
                  You earned Reward Points just by Viewing the Ad.
                  <br />
                  <br />
                  Invite Friends from the menu to earn more Points or Share
                  Business Profiles and Ads.
                  <br />
                  <br />
                  Check out Lottery and how to use the Points.
                </Trans>
              ),
            });
          }
          break;
        case ConsumerApi.SharingState.SelfShare:
          _rewardMessages.push({
            title: <Trans>Share with Others</Trans>,
            body: (
              <Trans>
                You can’t use the Ad Invitation Link yourself.
                <br />
                <br />
                If there has occurred an error, contact Customer Support. You
                can find the link in the menu.
              </Trans>
            ),
          });
          break;
        // TODO: In the server this should be AlreadyUsedBySomeoneElse
        case ConsumerApi.SharingState.Expired:
          _rewardMessages.push({
            title: <Trans>Invitation has already been used</Trans>,
            body: (
              <Trans>
                This Invitation Link has already been used.
                <br />
                <br />
                Invite Friends from the menu to earn more Reward Points or Share
                Business Profiles and Ads.
              </Trans>
            ),
          });
          break;
        // Logically impossible. Ignore.
        case ConsumerApi.SharingState.Teaser:
        default:
      }
    } else if (earnedPointsBySignup) {
      _rewardMessages.push({
        title: <Trans>Welcome to Brovs</Trans>,
        body: (
          <Trans>
            You earned Reward Points just by Signing Up.
            <br />
            <br />
            Invite Friends from the menu to earn more Points.
            <br />
            <br />
            Check out Lottery and how to use the Points.
          </Trans>
        ),
      });
    }

    setRewardMessages(_rewardMessages);
  }, [
    earnedPointsBySignup,
    referFriendUsedLink,
    referBusinessUsedLink,
    setLoading,
    apiService,
    referBusinessToken,
    closeRewardMessage,
    sharePlaceUsedLink,
    shareAdMessageUsedLink,
  ]);

  useEffect(() => {
    if (rewardMessages.length > 0) {
      document.addEventListener("click", clickOutsideOfRewardMessage);

      return () => {
        document.removeEventListener("click", clickOutsideOfRewardMessage);
      };
    }
  }, [clickOutsideOfRewardMessage, closeRewardMessage, rewardMessages]);

  return (
    <div
      className={`relative w-full mx-auto max-w-md text-18 flex items-center justify-center border-gray-lighther ${!props.hideBottomBorder ? "border-b" : ""}`}
      style={{ height: "60px", paddingTop: "3px" }}
    >
      {props.leftIcon || props.rightIcon ? (
        <div
          onClick={props.onClickLeftIcon}
          className={`flex-none pr-25px ${props.leftIcon ? "cursor-pointer" : ""}`}
          style={{ paddingLeft: "12px", minWidth: "50px" }}
        >
          {props.leftIcon}
        </div>
      ) : null}

      <div
        onClick={props.onClickTitle}
        className={`flex-auto text-center style-heading overflow-ellipsis truncate font-bold ${props.onClickTitle ? "cursor-pointer" : ""}`}
      >
        {props.title}
      </div>

      {props.leftIcon || props.rightIcon ? (
        <div
          onClick={props.onClickRightIcon}
          className={`flex-none pl-25px ${props.rightIcon ? "cursor-pointer" : ""}`}
          style={{ paddingRight: "12px", minWidth: "50px" }}
        >
          {props.rightIcon}
        </div>
      ) : null}

      {rewardMessages.length > 0 ? (
        <>
          <div className="absolute z-999999 left-[12px] top-[44px]">
            <svg
              width="22"
              height="19"
              viewBox="0 0 22 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 18.5H1.46667L8.49797 2.86146C9.62243 0.379512 12.3776 0.379512 13.502 2.86146C13.502 2.86146 20.253 18.5 20.5333 18.5C20.8136 18.5 21 18.5 21 18.5"
                stroke="#BDBDBD"
                stroke-linecap="round"
              />
              <path
                d="M12.9996 3.00008C11.9996 0.999993 9.99965 0.999975 8.99965 3.00002C8.99965 3.00002 3.90645 13.8979 2.49965 17.5C1.99965 18.5 1.69137 18.9998 1.19134 18.9998H20.8618C19.8615 18.9995 19.9419 18.6323 19.4997 17.5C18.0929 13.8979 12.9996 3.00008 12.9996 3.00008Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            ref={rewardMessageRef}
            className="absolute bg-white border border-gray-light px-[30px] pt-[30px] pb-[20px] max-w-[305px] z-99999 rounded-[5px] left-[12px] top-[62px]"
          >
            {rewardMessages.map((rewardMessage, i) => (
              <React.Fragment key={i}>
                <div className="mb-[20px] text-h7 text-center">
                  {rewardMessage.title}
                </div>
                <div className="mb-[20px] text-body">{rewardMessage.body}</div>
              </React.Fragment>
            ))}
            <div className="flex justify-end">
              <div
                onClick={closeRewardMessage}
                className="text-blue text-body cursor-pointer"
              >
                {t("OK")}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
