import React, { useCallback, useMemo, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiServiceState, loadingState, localeState } from "../atoms";
import { ConsumerApi } from "../ConsumerApi.dto";
import { useAdMessageModel } from "../hooks/UseAdMessageModel";
import {
  BookmarkCheckedIcon,
  BookmarkIcon,
  CheckOnCircleIcon,
  ShareIcon,
} from "../icons";
import { ShareAdMessage } from "../share-admessage";
import { PlaceHeader, PlaceContact, PlaceOpeningHours } from "../place";
import {
  adMessageBookmarksState,
  bookmarkAdMessageState,
  unbookmarkAdMessageState,
} from "../atoms/adMessageBookmarks";
import { OptionsMenu } from "../options-menu";
import { PlaceReportProblem } from "../place-report-problem";
import { ConfirmUnbookmark } from "../confirm-unbookmark";
import { ISODateTimeToLocalDateTime } from "../helpers/StringHelper";

const ExpandedPlace = React.lazy(() => import("../place/ExpandedPlace"));

type FinderAdMessageProps = {
  adMessage: ConsumerApi.AdMessageDto;
  type?: "finder" | "expanded" | "place" | "bookmark" | "teaser";
};

export const AdMessage: React.FC<FinderAdMessageProps> = (props) => {
  const type = props.type || "finder";

  const { t } = useTranslation();
  const locale = useRecoilValue(localeState);

  const setLoading = useSetRecoilState(loadingState);

  const adMessageDto = props.adMessage;
  const adMessage = useAdMessageModel(adMessageDto);

  const bookmarkAdMessage = useSetRecoilState(bookmarkAdMessageState);
  const unbookmarkAdMessage = useSetRecoilState(unbookmarkAdMessageState);
  const adMessageBookmarks = useRecoilValue(adMessageBookmarksState);

  const [expandedPlace, setExpandedPlace] = useState<ConsumerApi.PlaceDto>();
  const [extendedPlaceAdMessages, setExtendedPlaceAdMessages] =
    useState<ConsumerApi.AdMessageDto[]>();
  const [imageIndex, setImageIndex] = useState<number>(0);

  const [isSharingAdMessage, setIsSharingAdMessage] = useState<boolean>();

  const apiService = useRecoilValue(apiServiceState);
  const place = props.adMessage.place;

  const [showOverview, setShowOverview] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showReportProblem, setShowReportProblem] = useState(false);
  const [showConfirmUnbookmark, setShowConfirmUnbookmark] = useState<boolean>();
  const [starredCount, setStarredCount] = useState(
    adMessageDto.starredCount ?? 0,
  );

  const showPlace = useCallback(() => {
    setLoading(true);
    const request = new ConsumerApi.GetPlaceRequest({ placeGuid: place.guid });
    apiService
      .get(request)
      .then((response) => {
        setExpandedPlace(response.placeDto);
        setExtendedPlaceAdMessages(response.adMessages);
      })
      .finally(() => setLoading(false));
  }, [apiService, place.guid, setExpandedPlace, setLoading]);

  const hasBookmark = useMemo(
    () =>
      adMessageBookmarks &&
      adMessageBookmarks.some((a) => a.id === adMessageDto.id),
    [adMessageBookmarks, adMessageDto],
  );

  const bookmark = useCallback(() => {
    bookmarkAdMessage(adMessageDto);
    apiService
      .post(
        new ConsumerApi.StarredAdMessageRequest({
          adMessageId: adMessageDto.id,
        }),
      )
      .then(() => setStarredCount((starredCount) => starredCount + 1))
      .catch(() => unbookmarkAdMessage(adMessageDto));
  }, [adMessageDto, apiService, bookmarkAdMessage, unbookmarkAdMessage]);

  const unbookmark = useCallback(() => {
    unbookmarkAdMessage(adMessageDto);
    apiService
      .delete(
        new ConsumerApi.UnstarAdMessageRequest({
          adMessageId: adMessageDto.id,
        }),
      )
      .then(() => setStarredCount((starredCount) => starredCount - 1))
      .catch(() => bookmarkAdMessage(adMessageDto));
  }, [adMessageDto, apiService, bookmarkAdMessage, unbookmarkAdMessage]);

  const hasDescription =
    adMessage.description && adMessage.description.trim().length > 0;
  const hasMoreInformation =
    adMessage.moreInformation && adMessage.moreInformation.length > 0;
  const hasOpeningHours = adMessageDto.place.openingHours;

  return (
    <>
      <div className={type === "place" ? "mb-[15px]" : ""}>
        {type !== "place" ? (
          <PlaceHeader
            place={place}
            teaser={type === "teaser"}
            onClickOptionsMenu={() => setShowOptionsMenu(true)}
            onClickPlaceName={() => showPlace()}
            onClickOpeningHours={() => showPlace()}
          />
        ) : null}
        <div className="relative">
          <Splide
            onMoved={(splide, index, prev, dest) => setImageIndex(index)}
            options={{ arrows: false, pagination: false }}
          >
            {adMessageDto.imageUrls.map((img, i) => (
              <SplideSlide key={i}>
                <img
                  onClick={() => setShowOverview(!showOverview)}
                  className="w-full object-cover cursor-pointer"
                  alt="Place"
                  style={{ height: "356px" }}
                  src={img.medium}
                />
              </SplideSlide>
            ))}
          </Splide>
          {adMessageDto.imageUrls.length > 1 && (
            <div
              className="flex justify-center absolute w-full"
              style={{ height: "2px", bottom: "15px" }}
            >
              {adMessageDto.imageUrls.map((_, i) => (
                <div
                  key={i}
                  className="h-full w-5 mr-1 bg-white z-20"
                  style={{
                    marginRight:
                      i < adMessageDto.imageUrls.length - 1 ? "5px" : "0",
                    opacity: i === imageIndex ? "1" : "0.5",
                  }}
                ></div>
              ))}
            </div>
          )}
        </div>
        <div className="mt-[8px] px-[12px]">
          {type !== "teaser" && (
            <div className="flex items-center justify-end">
              <div
                className="px-[8px] flex justify-center cursor-pointer"
                onClick={() => setIsSharingAdMessage(true)}
              >
                <ShareIcon />
              </div>
              <div className="text-body text-right pl-[8px]">
                {starredCount > 0 ? starredCount : null}
              </div>
              <div
                className="flex justify-start px-[2px] cursor-pointer"
                onClick={() =>
                  hasBookmark ? setShowConfirmUnbookmark(true) : bookmark()
                }
              >
                {hasBookmark ? <BookmarkCheckedIcon /> : <BookmarkIcon />}
              </div>
            </div>
          )}
          <div className="mt-[10px] flex-auto text-body">
            {t("Starts")}:{" "}
            {ISODateTimeToLocalDateTime(adMessageDto.startAt as string, locale)}
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t("Ends")}:{" "}
            {ISODateTimeToLocalDateTime(adMessageDto.stopAt as string, locale)}
          </div>
          <div
            className={`mt-[8px] text-h6 ${type === "teaser" ? "pb-[20px]" : ""}`}
          >
            {adMessage.title}
          </div>
        </div>
        {type !== "teaser" && type !== "expanded" && !showOverview ? (
          <div className="w-full px-[12px]">
            <button
              onClick={() => setShowOverview(true)}
              className="mt-[8px] text-title-bold text-blue border-blue border h-[45px] rounded-[3px] w-full"
            >
              {t("View Ad")}
            </button>
            {type !== "place" && (
              <div className="flex justify-center mt-[20px]">
                <div className="w-[calc(80%+24px)] border-b-[2px] border-black"></div>
              </div>
            )}
          </div>
        ) : type !== "teaser" ? (
          <>
            <div className="px-[12px]">
              {hasDescription && (
                <div className="text-body mt-[15px]">
                  {adMessage.description}
                </div>
              )}
              {hasMoreInformation && (
                <div className="py-[15px]">
                  <div className="text-title-bold">{t("More Information")}</div>
                  {adMessage.moreInformation!.map((information, i) => (
                    <div key={i} className="mt-[15px] flex items-center">
                      <CheckOnCircleIcon />
                      <div className="pl-[10px] text-body">{information}</div>
                    </div>
                  ))}
                </div>
              )}
              {hasOpeningHours && (
                <PlaceOpeningHours
                  openingHours={adMessageDto.place.openingHours!}
                />
              )}
              {(hasMoreInformation || hasDescription || hasOpeningHours) && (
                <div className="w-full border-b border-gray-lighther height-[1px]"></div>
              )}
            </div>

            <PlaceContact place={adMessageDto.place} />

            {type !== "place" && (
              <>
                <div className="px-[12px] pt-[15px]">
                  <button
                    onClick={() => showPlace()}
                    className="flex items-center justify-center cursor-pointer text-body w-full"
                  >
                    {t("View")} {adMessageDto.place.name}
                  </button>
                </div>

                <div className="flex justify-center mt-[20px]">
                  <div className="w-[calc(80%+24px)] border-b-[2px] border-black"></div>
                </div>
              </>
            )}
          </>
        ) : null}
      </div>

      {expandedPlace ? (
        <ExpandedPlace
          place={expandedPlace}
          adMessages={extendedPlaceAdMessages}
          onBack={() => setExpandedPlace(undefined)}
        />
      ) : showOptionsMenu ? (
        <OptionsMenu
          onClose={() => setShowOptionsMenu(false)}
          onClickUnbookmark={
            hasBookmark
              ? () => {
                  setShowOptionsMenu(false);
                  setShowConfirmUnbookmark(true);
                }
              : undefined
          }
          onClickBookmark={
            !hasBookmark
              ? () => {
                  setShowOptionsMenu(false);
                  bookmark();
                }
              : undefined
          }
          onClickReportProblem={() => {
            setShowOptionsMenu(false);
            setShowReportProblem(true);
          }}
        />
      ) : showReportProblem ? (
        <PlaceReportProblem onClose={() => setShowReportProblem(false)} />
      ) : showConfirmUnbookmark ? (
        <ConfirmUnbookmark
          onClose={() => setShowConfirmUnbookmark(false)}
          onClickConfirm={() => {
            setShowConfirmUnbookmark(false);
            unbookmark();
          }}
        />
      ) : isSharingAdMessage ? (
        <ShareAdMessage
          adMessage={adMessage}
          onClose={() => setIsSharingAdMessage(false)}
        />
      ) : null}
    </>
  );
};
