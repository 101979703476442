import { ConsumerApi } from "../ConsumerApi.dto";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SeparatorHeader } from "../separator-header";

type EarnRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const EarnRewardPoints: React.FC<EarnRewardPointsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <SeparatorHeader title={t("Earn Reward Points")} />
      <div className="flex items-center text-title mt-[15px]">
        <Trans>
          You earn Rewards Points simply by Inviting Friends to use Brovs,
          Inviting Owners or Employees of a Business to Manage their Business
          Profile for free, Share Business Profiles or Ads with people you might
          think have an interest and by viewing Shared Businesses and Ads.
        </Trans>
      </div>
      <div className="flex justify-end mt-[15px]">
        <div className="flex-none text-title-bold">{t("Points")}</div>
      </div>
      {[
        [
          t("Invite Friends"),
          props.rewardsStatus.referFriend.pointsGrantedOnSuccess,
        ],
        [
          t("Invite Business Owners or Employees"),
          props.rewardsStatus.referBusiness.pointsGrantedOnSuccess,
        ],
        [
          t("Shared Businesses"),
          props.rewardsStatus.sharePlace.pointsGrantedOnSuccess,
        ],
        [
          t("Shared Ads"),
          props.rewardsStatus.shareAdMessage.pointsGrantedOnSuccess,
        ],
        [t("Sign Up Bonus"), props.rewardsStatus.pointsAwardedForSignup],
      ].map(([title, value], i) => (
        <div className="flex mt-[12px] items-center" key={i}>
          <div className="flex-auto style-body">{title}</div>
          <div className="flex-none text-title-bold">{value}</div>
        </div>
      ))}
    </>
  );
};

export default EarnRewardPoints;
