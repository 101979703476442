import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VippsButton } from "vipps-button"
import { ContinueWithPhone } from "./ContinueWithPhone";

interface CommonButtonsProps {

  forImageBackground: boolean;
}

export const CommonButtons: React.FC<CommonButtonsProps> = (props) => {
  const { t } = useTranslation();
  const [continueWithPhone, setContinueWithPhone] = useState(false);

  return (
    !continueWithPhone ?
      <>
        <VippsButton/>
        <div className="flex justify-center items-center mt-[20px]">
          <div className={`text-mid-bold flex justify-center ${props.forImageBackground ? 'text-white' : 'text-black'}`}>{t("OR")}</div>
        </div>
        <div
          onClick={() => setContinueWithPhone(true)}
          className={`text-title-medium-underline text-center w-full ${props.forImageBackground ? 'text-white' : 'text-black'} cursor-pointer mt-[20px]`}>
          {t("Continue with Mobile Number")}
        </div>
      </>
    : <ContinueWithPhone onBack={() => setContinueWithPhone(false)} />
  )

}
