import { Trans, useTranslation } from "react-i18next";
import logo from "../images/logo.svg";
import { CommonButtons } from "./CommonButtons";

interface SignInProps {
  content: React.ReactNode
}

export const SignIn: React.FC<SignInProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className="h-full overflow-y-scroll pb-[64px] pt-[64px]"
      style={{
          backgroundImage: `url(${require("../images/welcome-background.jpg")})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0,0,0,.5)',
          backgroundBlendMode: 'multiply'
      }}>
      <div className="w-full mx-auto max-w-md px-15px">
        <div className="flex justify-center">
          <img src={logo} alt="logo"/>
        </div>

        {props.content}
        
        <div className="font-bold text-center text-white w-full mt-[72px] mb-[24px] text-h7">
          {t("Easy and FREE Sign In")}
        </div>
      </div>
      <CommonButtons forImageBackground={true} />
    </div>
  )

}
