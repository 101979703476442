import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ConsumerApi } from "../ConsumerApi.dto";
import { MenuIcon, CancelIcon } from "../icons";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiServiceState, lastLocationState, showSidebarState } from "../atoms";
import { defaultSearchRadiusInKm, pageSize } from "../Constants";
import { useTranslation } from "react-i18next";
import { loadingState } from "../atoms/loading";
import { FinderTab, TabType } from "../finder-tab";
import { Place } from "../place";
import { AdMessage } from "../ad-message";
import { Header } from "../header";
import { useFinderParams } from "../hooks/UseFinderParams";
import { usePlaceLocation } from "../hooks/UsePlaceLocation";
import { useFinderScroll } from "../hooks/UseFinderScroll";
import { DateTime } from "luxon";

export const FinderSelected: React.FC = () => {
  const { t } = useTranslation();

  const setShowSidebar = useSetRecoilState(showSidebarState);
  const setLoading = useSetRecoilState(loadingState);
  const lastLocation = useRecoilValue(lastLocationState);
  const apiService = useRecoilValue(apiServiceState);

  const [rootCategories, setRootCategories] =
    useState<ConsumerApi.CategoryFacetTreeDto[]>();
  const [places, setPlaces] = useState<ConsumerApi.PlaceDto[]>();
  const [adMessages, setAdMessages] = useState<ConsumerApi.AdMessageDto[]>();
  const [queryLocationDto, setQueryLocationDto] =
    useState<ConsumerApi.QueryLocationDto>();

  const [adMessageCount, setAdMessageCount] = useState<number>();
  const [placeCount, setPlaceCount] = useState<number>();

  const [isFetching, setIsFetching] = useState(false);

  const googleAttribution = useRef<HTMLDivElement>(null);
  const scrollableAreaRef = useRef<HTMLDivElement>(null);

  const { paramPlaceGuid, paramAdMessageGuid, paramLocation, paramShared } =
    useFinderParams();

  const [selectedTab, setSelectedTab] = useState<TabType>();
  const { offsetAdMessages, onScroll, resetScroll } = useFinderScroll(
    selectedTab,
    scrollableAreaRef,
  );

  const { paramTab } = useParams<any>();

  const {} = usePlaceLocation(
    googleAttribution,
    "finder",
    paramTab,
    paramLocation,
    undefined,
    paramPlaceGuid,
    paramAdMessageGuid,
    undefined,
    paramShared,
  );

  const fetch = useCallback(
    (cacheVersion?: number) => {
      if (queryLocationDto && (paramPlaceGuid || paramAdMessageGuid)) {
        setIsFetching(true);
        resetScroll();
        apiService
          .post(
            new ConsumerApi.FindRequest({
              offset: 0,
              size: pageSize,
              resultAt: new Date().toUTCString(),
              cacheVersion: cacheVersion,
              filter: new ConsumerApi.QueryFilterDto({
                placeGuid: paramPlaceGuid,
                adMessageGuid: paramAdMessageGuid,
              }),
              location: queryLocationDto,
            }),
          )
          .then((response) => {
            let rootCategories: ConsumerApi.CategoryFacetTreeDto[] = [];

            if (response.places.length > 0) {
              const rootCategory = Object.values(response.places[0].categories)
                .filter((c) => c.absoluteSlug.split(".").length <= 2)
                .sort((c0, c1) =>
                  c0.absoluteSlug.split(".").length <
                  c1.absoluteSlug.split(".").length
                    ? 1
                    : -1,
                )[0];
              if (rootCategory) {
                rootCategories.push(rootCategory);
              }
            }

            setRootCategories(rootCategories);
            setPlaces(response.places);
            setAdMessages(response.adMessages);
            setAdMessageCount(response.adMessageCount);
            setPlaceCount(response.placeCount);

            scrollableAreaRef.current?.scrollTo({ top: 0 });
          })
          .finally(() => setIsFetching(false));
      }
    },
    [apiService, queryLocationDto, paramPlaceGuid, paramAdMessageGuid],
  );

  const fetchMoreAdMessages = useCallback(() => {
    apiService
      .post(
        new ConsumerApi.FindAdMessageRequest({
          offset: offsetAdMessages,
          size: pageSize,
          resultAt: new Date().toUTCString(),
          filter: new ConsumerApi.QueryFilterDto({
            placeGuid: paramPlaceGuid,
            adMessageGuid: paramAdMessageGuid,
          }),
          location: queryLocationDto,
        }),
      )
      .then((response) => {
        setAdMessages((adMessages) => [
          ...(adMessages || []),
          ...response.adMessages,
        ]);
      });
  }, [apiService, offsetAdMessages, paramPlaceGuid, paramAdMessageGuid]);

  const onClickTab = useCallback(() => {
    scrollableAreaRef.current?.scrollTo({ top: 0 });
  }, [scrollableAreaRef]);

  useEffect(() => {
    setSelectedTab(paramTab as TabType);
  }, [paramTab]);

  useEffect(() => {
    if (offsetAdMessages > 0) fetchMoreAdMessages();
  }, [offsetAdMessages, fetchMoreAdMessages]);

  useEffect(
    () => fetch(),
    [fetch, paramPlaceGuid, paramAdMessageGuid, paramLocation],
  );

  useEffect(() => setLoading(isFetching), [isFetching, setLoading]);

  useEffect(() => {
    if (lastLocation) {
      let queryLocationDto = new ConsumerApi.QueryLocationDto({
        latitude: lastLocation.location.lat,
        longitude: lastLocation.location.lng,
        searchRadiusInKm: defaultSearchRadiusInKm,
      });
      setQueryLocationDto(queryLocationDto);
    }
  }, [lastLocation]);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-30">
        <div className="w-full mx-auto max-w-md bg-white">
          <Header
            leftIcon={<MenuIcon />}
            onClickLeftIcon={() => setShowSidebar(true)}
            onClickTitle={() => fetch(DateTime.now().toMillis())}
            title={"BROVS"}
          />
        </div>
      </div>
      <div
        ref={scrollableAreaRef}
        className="h-full overflow-y-scroll overflow-x-hidden pt-[60px]"
        onScroll={onScroll}
      >
        <div className="bg-near-white sticky z-20 top-[-132px]">
          {rootCategories &&
          rootCategories.length > 0 &&
          places &&
          places.length > 0 ? (
            <div className="flex h-[132px]">
              <div className="flex-none h-full w-full flex relative">
                <img
                  className="absolute h-full w-full inset-0 z-0 object-cover"
                  alt={rootCategories[0].name}
                  src={rootCategories[0].imageUrl}
                />
                <div className="absolute inset-0 bg-blue bg-opacity-70 z-10"></div>
                <div className="absolute inset-0 z-20">
                  <div className="flex justify-between items-center mt-2 text-white">
                    <Link
                      className="cursor-pointer px-3 py-2"
                      to={`/finder/${paramTab}/?location=${paramLocation}`}
                    >
                      <CancelIcon />
                    </Link>
                  </div>
                  <div className="flex-none text-white text-24 font-extrabold text-center w-full px-3 mt-[12px]">
                    {places[0].name}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-2.5 h-9 flex">
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.adMessages}
              selected={selectedTab === TabType.adMessages}
              count={adMessageCount}
            />
            <FinderTab
              onClick={onClickTab}
              tabType={TabType.places}
              selected={selectedTab === TabType.places}
              count={placeCount}
            />
          </div>
        </div>

        {!places || !adMessages ? (
          <div>Loading...</div>
        ) : paramTab === TabType.places ? (
          places?.map((p) => <Place key={p.id} place={p} />)
        ) : (
          adMessages?.map((a) => <AdMessage key={a.id} adMessage={a} />)
        )}
      </div>

      <div ref={googleAttribution}></div>
    </>
  );
};
