import { ConsumerApi } from "../ConsumerApi.dto";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SeparatorHeader } from "../separator-header";
import { Button } from "../button";
import { useNavigate } from "react-router-dom";

type EarnRewardPointsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const EarnRewardPoints: React.FC<EarnRewardPointsProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <SeparatorHeader title={t("Use Reward Points")} />
      <div className="flex items-center text-title mt-[15px]">
        <Trans>
          You can use the earned Brovs Reward Points to get Lottery Tickets in
          the Brovs Lottery.
          <br />
          <br />
          One Reward Point = One Lottery Ticket
          <br />
          <br />
          Go To Lottery for more information.
          <br />
          <br />
          Best of luck
        </Trans>
      </div>
      <div className="mt-[30px]">
        <Button
          title={t("Go To Lottery")}
          onClick={() => navigate("/lottery")}
        />
      </div>
    </>
  );
};

export default EarnRewardPoints;
