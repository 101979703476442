import { ConsumerApi } from "../ConsumerApi.dto";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ShareIcon } from "../icons";
import DisplayPoints from "./DisplayPoints";

type SharePlacesProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

export const SharedPlaces: React.FC<SharePlacesProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayPoints
        title={t("Share Business Profiles")}
        description={
          <Trans>
            Share Business Profiles with people. You will earn Reward Points
            when Business Profiles you shared are viewed. You can share directly
            from the Business Profile.
          </Trans>
        }
        points={props.rewardsStatus.sharePlace.points}
      />
      <div className="text-body mt-[20px] flex justify-center text-center">
        {t("Use Business Profile Share Icon")}
      </div>
      <div className="mt-[10px] flex justify-center">
        <ShareIcon />
      </div>
    </>
  );
};

export default SharedPlaces;
