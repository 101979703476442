import { ConsumerApi } from "../ConsumerApi.dto";
import { convertSharedPlaceToPlaceDto } from "./PlaceHelper"

export const convertSharedAdMessageToAdMessageDto = (sharedAdMessage: ConsumerApi.SharedAdMessage, sharedPlace: ConsumerApi.SharedPlace): ConsumerApi.AdMessageDto => {
  return new ConsumerApi.AdMessageDto({
      guid: sharedAdMessage.guid,
      id: sharedAdMessage.id,
      startAt: sharedAdMessage.startAt,
      stopAt: sharedAdMessage.stopAt,
      detailsUnion: new ConsumerApi.AdMessageDetailsUnion({
          freetext: new ConsumerApi.AdMessageDetailsFreetext({
              title: sharedAdMessage.title,
          })
      }),
      imageUrls: [new ConsumerApi.ImageUrls({
          preview: sharedAdMessage.imageUrl,
          small: sharedAdMessage.imageUrl,
          medium: sharedAdMessage.imageUrl,
          large: sharedAdMessage.imageUrl
      })],
      place: convertSharedPlaceToPlaceDto(sharedPlace)
  })
}