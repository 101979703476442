import React, { Ref, useCallback, useState } from 'react';
import { CancelIcon, CloseInputIcon, SearchIcon } from '../icons';

type InputSearchProps = {
  reference?: Ref<HTMLInputElement>,
  autoFocus?: boolean,
  placeholder?: string,
  autoComplete?: string,
  readOnly?: boolean,
  hideClear?: boolean,
  value?: string,
  onFocus?: () => void,
  onBlur?: () => void,
  onClear?: () => void,
  onClick?: () => void,
  onChange?: (value: string) => void
}

export const InputSearch: React.FC<InputSearchProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  }, [props])

  const onBlur = useCallback(() => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  }, [props])

  return (
    <div
      onClick={props.onClick}
      className={`flex items-center w-full h-[56px] px-[10px] bg-white border ${isFocused ? "border-blue-light" : "border-gray-light"}`}>
      <div className="flex justify-center w-[24px]">
        <SearchIcon />
      </div>
      <input
        ref={props.reference}
        autoFocus={props.autoFocus}
        onBlur={onBlur}
        onFocus={onFocus}
        value={props.value || ""}
        readOnly={props.readOnly}
        enterKeyHint="search"
        onChange={(e) => props.onChange ? props.onChange(e.target.value) : null}
        className="px-[10px] border-0 text-title w-full outline-none"
        placeholder={props.placeholder} />
      {props.value && !props.hideClear ?
        <div
          className="cursor-pointer flex justify-center w-[24px]"
          onClick={(e) => { e.stopPropagation(); props.onClear && props.onClear(); }}>
          <CancelIcon />
        </div>
      : null}
    </div>
  )
}