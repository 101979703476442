import React from "react";
import { useTranslation } from "react-i18next";

interface DisplayPointsProps {
  title: string;
  description: React.ReactNode;
  points?: number;
}

const DisplayPoints: React.FC<DisplayPointsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-title-bold">{props.title}</div>
      <div className="flex items-center mt-[10px] text-title">
        {props.description}
      </div>
      <div className="mt-[30px]">
        <div className="text-body flex justify-center text-center">
          {t("Text Message / SMS")}
          <br />
          {t("(Only Norwegian Numbers)")}
        </div>
        <div className="text-h4 flex justify-center mt-[5px]">
          {props.points ?? 0}
        </div>
        <div className="text-body flex justify-center text-center">
          {t("Points")}
        </div>
      </div>
    </>
  );
};

export default DisplayPoints;
