import { googleMapsNavigationBaseUrl } from "../Constants";
import { ConsumerApi } from "../ConsumerApi.dto";
import { LocationIcon } from "../icons/location";
import { PlaceLocation } from "../model";
import { isSharedPlaceDto } from "model/Place";

export const formatAddress = (placeDto: ConsumerApi.PlaceBasicDto) => {
  var address = "";

  if (placeDto.address != null) {
    if (placeDto.address.street) address += `<br>${placeDto.address.street}`;
    if (placeDto.address.postalCode) address += `<br>${placeDto.address.postalCode}`;
    if (placeDto.address.region) {
      if (placeDto.address.postalCode) address += ` ${placeDto.address.region.toUpperCase()}`;
      else address += placeDto.address.region.toUpperCase();
    }
  }

  if (address.length > 0) {
    if (address.startsWith("<br>")) {
      address = address.substring(4);
    } else if (address.startsWith(' ')) {
      address = address.substring(1);
    }
  }
  return address;
}

export const formatPriceMainDish = (priceMainDish: ConsumerApi.PriceMainDishDto): string => {
  switch (priceMainDish.slug) {
    case ConsumerApi.PriceMainDish.FineDining:
      return "$$$$";
    case ConsumerApi.PriceMainDish.High:
      return "$$$";
    case ConsumerApi.PriceMainDish.Medium:
      return "$$";
    case ConsumerApi.PriceMainDish.Value:
    default:
      return "$";
  }
}

const deg2rad = (deg: number) => {
  return (deg * Math.PI / 180.0);
}

const rad2deg = (rad: number) => {
  return (rad / Math.PI * 180.0);
}

export const calculateDistance = (
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number,
  unit: ConsumerApi.DistanceUnit): number => {

  const theta = lon1 - lon2;
  let dist = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));
  dist = Math.acos(dist);
  dist = rad2deg(dist);
  dist = dist * 60 * 1.1515;
  switch (unit)
  {
    case ConsumerApi.DistanceUnit.Kilometer:
      dist = dist * 1.609344;
      break;
    case ConsumerApi.DistanceUnit.Mile:
      // Miles are nautical miles
      dist = dist * 0.8684;
      break;
  }
  return dist;
}

export const distanceDescription = (distance: number, unitType: ConsumerApi.DistanceUnit) => {
  switch (unitType) {
    case ConsumerApi.DistanceUnit.Kilometer:
      return distanceInKilometers(distance);
    case ConsumerApi.DistanceUnit.Mile:
      return distanceInMiles(distance);
    default:
      throw new Error(`DistanceUnit ${unitType} not supported`);
  }
}

const distanceInKilometers = (distance: number) => {
  if (distance < 1) {
    distance = distance * 1000;
    return `${distance.toFixed(0)}m`;
  } else if (distance <= 10) {
    return `${distance.toFixed(1)}km`;
  } else {
    return "10km+";
  }
}

const distanceInMiles = (distance: number) => {
  if (distance < 1) {
    // Miles are nautical miles
    distance = distance * 2025.37;
    return `${distance.toFixed(0)}yd`;
  }
  else if (distance <= 6) {
    return `${distance.toFixed(1)}mi`;
  } else {
    return `6mi+`;
  }
}

export const googleMapsNavigationUrl = (latitude: number, longitude: number): string => {
  return `${googleMapsNavigationBaseUrl}&destination=${latitude},${longitude}`;
}

export const convertAutocompletePredictionToPlaceLocation = ((prediction: google.maps.places.AutocompletePrediction): PlaceLocation => {
  const descriptionArray = prediction.description.split(",");
  const name = descriptionArray[0];
  const description = descriptionArray.reduce((d, c) => {
    if (d.length > 0) {
      d += ", "
    }
    return d += c;
  })
  const extendedName = descriptionArray.length > 1 || descriptionArray[0] !== name ?
    `${name}, ${descriptionArray[descriptionArray.length - 1]}` :
    name;

  return new PlaceLocation({
    name: name,
    extendedName: extendedName,
    description: description,
    placeId: prediction.place_id,
    locationType: ConsumerApi.RecentLocationType.Named,
    icon: <LocationIcon type={ConsumerApi.RecentLocationType.Pin} />,
  })
})


export const convertSharedPlaceToPlaceDto = (sharedPlace: ConsumerApi.SharedPlace | ConsumerApi.ReferredPlace): ConsumerApi.PlaceDto => {
  return new ConsumerApi.PlaceDto({
      name: sharedPlace.name,
      guid: sharedPlace.guid,
      id: sharedPlace.id,
      imageUrl: new ConsumerApi.ImageUrls({
          preview: sharedPlace.imageUrl,
          small: sharedPlace.imageUrl,
          medium: sharedPlace.imageUrl,
          large: sharedPlace.imageUrl
      }),
      logoUrl: sharedPlace.logoUrl ? new ConsumerApi.ImageUrls({
          preview: sharedPlace.logoUrl,
          small: sharedPlace.logoUrl,
          medium: sharedPlace.logoUrl,
          large: sharedPlace.logoUrl
      }) : undefined,
      categories: isSharedPlaceDto(sharedPlace) ? sharedPlace.rootCategories.map(c => new ConsumerApi.CategoryDto({
          name: c.name,
          absoluteSlug: c.absoluteSlug
      })) : undefined,
      wearPriceProfiles: isSharedPlaceDto(sharedPlace) ? sharedPlace.wearPriceProfiles?.map(wp => new ConsumerApi.WearPriceProfileDto({
          slug: wp.slug,
          label: wp.label
      })) : undefined,
      priceMainDish: isSharedPlaceDto(sharedPlace) && sharedPlace.priceMainDish ? new ConsumerApi.PriceMainDishDto({
          title: sharedPlace.priceMainDish.label,
          slug: sharedPlace.priceMainDish.slug,
      }) : undefined,
      openingHours: isSharedPlaceDto(sharedPlace) ? sharedPlace.openingHours : undefined,
      timeZoneDto: isSharedPlaceDto(sharedPlace) ? sharedPlace.timeZoneDto : undefined
  })
}