import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ConsumerApi } from "../ConsumerApi.dto";
import DisplayPoints from "./DisplayPoints";

type BusinessReferralsProps = {
  rewardsStatus: ConsumerApi.GetRewardsStatusResponse;
};

const BusinessReferrals: React.FC<BusinessReferralsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayPoints
        title={t("Invite Business Owners or Employees")}
        description={
          <Trans>
            Invite Business Owners or Employees to Manage their Business Profile
            for free. You will earn Reward Points when they access their
            Business Profile. You can invite directly from the Business Profile.
          </Trans>
        }
        points={props.rewardsStatus.referBusiness.points}
      />
    </>
  );
};

export default BusinessReferrals;
